import React, { useState , useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Slider } from 'rsuite';
import { updateFilter } from "../../../../actions/filter.action";
import DataStore from  "./../../common/DataStore";

const MonthFilter = ({mobile}) => {
    const [months] = useState(DataStore.getMonths());
    const [maxPeriod] = useState(12);
    const dispatch = useDispatch();
    const {period, year} = useSelector(state => state.filters.header);

    useEffect(() => validateSlider(period, "T"), [year]);

    const validateSlider = (v, mode) => {
      if(year === new Date().getFullYear()){
        if(v <= new Date().getMonth()+1){
          onFilterChange(v);
        }else{
          if(mode == "T"){
            onFilterChange(parseInt(new Date().getMonth()+1));
          }
        }
      }else{
        onFilterChange(v);
      }
    }

    const onFilterChange = (period) => {
        let previous_period = period - 1;
        if (period == 1) {
            previous_period = 12
        }

        dispatch(updateFilter({
            period,
            previous_period
        }))
    }

    const onSelectionChange = (event) => {
        const period = event.target.value;
        if (period) {
            onFilterChange(parseInt(period))
        }
    }

    if (mobile) {
        return (
            <div className="form-group">
                <label htmlFor="sel1">Month</label>
                <select className="form-control" id="sel1" onChange={onSelectionChange} value={period}>
                    {
                        months.map((month, i) => {
                            return <option key={month} value={(i+1)}>{month}</option>
                        })
                    }
                </select>
            </div>
        )
    }

    return (
      <Slider
        defaultValue={period}
        className="month-filter"
        step={1}
        graduated
        min={1}
        max={maxPeriod}
        tooltip={false}
        value={period}
        renderMark={mark => {
          return months[mark-1];
        }}
        onChange={v => {
          validateSlider(v,"S");
        }}
      />
    );
}

export default MonthFilter;


