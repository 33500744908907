import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { subSections } from '../../constant/vetTrack/analysis';

// VETTRACK_ANALYSIS_RISK_ANALYSIS_ACADEMIC //
const academicPath = 'vetTrack.analysis.riskAnalysis.academic';
const academicData = state => _get(state, academicPath, {});

export const getDashboardAcademicData = createSelector(
  academicData, data => data
);

export const getAcademicCardsLoading = createSelector(
  academicData, data => _get(data, 'cards.isLoading', false),
);

export const getAcademicCardsData = createSelector(
  academicData, data => _get(data, 'cards.data'),
);

export const getAcademicStudentsLoading = createSelector(
  academicData, data => _get(data, 'students.isLoading', false),
);

export const getAcademicStudentsData = createSelector(
  academicData, data => _get(data, 'students.data.students', []),
);

export const getAcademicFactorsData = createSelector(
  academicData, data => _get(data, 'students.data.enabledFactors'),
);

export const getAcademicOverviewLoading = createSelector(
  academicData, data => _get(data, 'overview.isLoading', false),
);

export const getAcademicOverviewData = createSelector(
  academicData, data => _get(data, 'overview.data'),
);

export const getAcademicOverviewDisabilitiesResponse = createSelector(
  academicData, data => _get(data, 'disabilities'),
);

export const getAcademicOverviewUnitProgressResponse = createSelector(
  academicData, data => _get(data, 'unitProgress'),
);

export const getAcademicOverviewAttendanceResponse = createSelector(
  academicData, data => _get(data, 'attendance'),
);

// VETTRACK_ANALYSIS_RISK_ANALYSIS_ACADEMIC_END //

// VETTRACK_ANALYSIS_RISK_ANALYSIS_COMMON //
const commonPath = 'vetTrack.analysis.riskAnalysis.common';
const commonData = state => _get(state, commonPath, {});
export const getDashboardCommonData = createSelector(
  commonData, data => data,
);

export const getDashboardFilterData = createSelector(
  commonData, data => _get(data, 'filters'),
);

export const getDashboardFiltersFetchLoading = createSelector(
  commonData, data => _get(data, 'filters.fetched.isLoading', false),
);

export const getDashboardFiltersFetchData = createSelector(
  commonData, data => _get(data, 'filters.fetched.data.filters'),
);

export const getDashboardFiltersSelectedData = createSelector(
  commonData, data => _get(data, 'filters.selected'),
);

export const getSelectedCardsByPath = path => (
  createSelector(commonData, data => (
    _get(data, `selectedCards.${path}`)
  ))
);
// VETTRACK_ANALYSIS_RISK_ANALYSIS_COMMON_END//

// VETTRACK_ANALYSIS_RISK_ANALYSIS_COMPLIANCE
const compliancePath = 'vetTrack.analysis.riskAnalysis.compliance';
const complianceData = state => _get(state, compliancePath, {});

export const getDashboardComplianceData = createSelector(
  complianceData, data => data,
);
export const getComplianceCardsLoading = createSelector(
  complianceData, data => _get(data, 'cards.isLoading', false),
);
export const getComplianceCardsData = createSelector(
  complianceData, data => _get(data, 'cards.data'),
);
export const getComplianceStudentsLoading = createSelector(
  complianceData, data => _get(data, 'students.isLoading', false),
);
export const getComplianceStudentsData = createSelector(
  complianceData, data => _get(data, 'students.data.students', []),
);
export const getComplianceFactorsData = createSelector(
  complianceData, data => _get(data, 'students.data.enabledFactors'),
);
export const getComplianceOverviewLoading = createSelector(
  complianceData, data => _get(data, 'overview.isLoading', false),
);
export const getComplianceOverviewData = createSelector(
  complianceData, data => _get(data, 'overview.data'),
);
export const getComplianceOverviewDurationToExpire = createSelector(
  complianceData, data => _get(data, 'durationToExpire'),
);
// VETTRACK_ANALYSIS_RISK_ANALYSIS_COMPLIANCE_END

// VETTRACK_ANALYSIS_RISK_ANALYSIS_FINANCIAL
const financialPath = 'vetTrack.analysis.riskAnalysis.financial';
const financialData = state => _get(state, financialPath, {});

export const getDashboardFinancialData = createSelector(
  financialData, data => data,
);

export const getFinancialCardsLoading = createSelector(
  financialData, data => _get(data, 'cards.isLoading', false),
);
export const getFinancialCardsData = createSelector(
  financialData, data => _get(data, 'cards.data'),
);
export const getFinancialStudentsLoading = createSelector(
  financialData, data => _get(data, 'students.isLoading', false),
);
export const getFinancialStudentsData = createSelector(
  financialData, data => _get(data, 'students.data.students', []),
);
export const getFinancialFactorsData = createSelector(
  financialData, data => _get(data, 'students.data.enabledFactors'),
);
export const getFinancialOverviewLoading = createSelector(
  financialData, data => _get(data, 'overview.isLoading', false),
);
export const getFinancialOverviewData = createSelector(
  financialData, data => _get(data, 'overview.data'),
);
// VETTRACK_ANALYSIS_RISK_ANALYSIS_FINANCIAL_END

// VETTRACK_ANALYSIS_RISK_ANALYSIS_OVERVIEW
const overviewPath = 'vetTrack.analysis.riskAnalysis.overview';
const overviewData = state => _get(state, overviewPath, {});

export const getDashboardOverviewData = createSelector(
  overviewData, data => data,
);

export const getOverviewChartsLoading = createSelector(
  overviewData, data => _get(data, 'charts.isLoading', false),
);
export const getOverviewChartsData = createSelector(
  overviewData, data => _get(data, 'charts.data'),
);

export const getOverviewStudentsLoading = createSelector(
  overviewData, data => _get(data, 'students.isLoading', false),
);
export const getOverviewStudentsData = createSelector(
  overviewData, data => _get(data, 'students.data.students'),
);
// VETTRACK_ANALYSIS_RISK_ANALYSIS_OVERVIEW_END

// VETTRACK_ANALYSIS_LAYOUT
const sidebar = 'vetTrack.analysis.layout.sidebar';
const sidebarData = state => _get(state, sidebar, {});
export const getSidebarData = createSelector(
  sidebarData, data => data,
);

export const getSidebarCollapsed = createSelector(
  sidebarData, data => _get(data, 'collapsed', false),
);
export const getSidebarEnabled = createSelector(
  sidebarData, data => _get(data, 'isSidebar', false),
)
// VETTRACK_ANALYSIS_LAYOUT_END

// VETTRACK_ANALYSIS_SETTINGS_BASE
const basePath = `vetTrack.analysis.settings`;
const baseData = state => _get(state, basePath, {});

export const getData = createSelector(
  baseData, data => data,
)

export const getSettingBySections = (endpoint, section, subSection = null) => (
  createSelector(baseData, data => {
    const path = `${section}.${subSection ? `${subSection}.` : ''}${endpoint}`;
    return _get(data, `${path}`, undefined);
  })
);

export const getBaseSettingByPath = (path) => (
  createSelector(baseData, data => _get(data, `${path}`, undefined))
);
// VETTRACK_ANALYSIS_SETTINGS_BASE_END

// VETTRACK_ANALYSIS_SETTINGS_COMMON_SETTINGS
const settingsCommonPath = 'vetTrack.analysis.settings.common';
const settingsCommonData = state => _get(state, settingsCommonPath, {});

export const getSettingsData = createSelector(
  settingsCommonData, data =>  data,
);
export const getUserPermissions = createSelector(
  settingsCommonData, data => _get(data, 'permissions'),
);
export const getUserPermissionsLoading = createSelector(
  settingsCommonData, data => _get(data, 'permissions.isLoading', false),
);
export const getUserPermissionsData = createSelector(
  settingsCommonData, data => _get(data, 'permissions.data.permissions'),
);

export const getPermissionDataByPath = path => (
  createSelector(settingsCommonData, data =>
    _get(data, `permissions.data.permissions.${path}`, undefined),
  )
);

export const getIsEdited = createSelector(
  settingsCommonData, data => _get(data, 'isEdited', {}),
);

export const getDiscardDraftLoading = createSelector(
  settingsCommonData, data => _get(data, 'discardDraft.isLoading', false),
);
// VETTRACK_ANALYSIS_SETTINGS_COMMON_SETTINGS_END

// VETTRACK_ANALYSIS_SETTINGS_DATASOURCES_SETTINGS
const settingDataSource = 'vetTrack.analysis.settings.dataSources';
const dataSources = state => _get(state, settingDataSource, {});

export const getDataSourcesData = createSelector(
  dataSources, data => data,
);

export const getDataSourcesFetchDataLoading = createSelector(
  dataSources, data => _get(data, 'selected.isLoading', false),
);
export const getDataSourcesSaveLoading = createSelector(
  dataSources, data => _get(data, 'saved.isLoading', false),
);
export const getDataSourcesDataResponse = createSelector(
  dataSources, data => _get(data, 'data'),
);

export const getDataSourcesSettingByPath = path => (
  createSelector(dataSources, data => 
    _get(data, `data.${path}`, undefined),
  )
);

export const makeDataSourceSyncStatus = createSelector(
  dataSources , data => _get(data, 'syncData', {}),
);

export const getDrawerSelected = createSelector(
  dataSources, data => _get(data, 'drawerSelected'),
);
export const getDrawerSelectedLoading = createSelector(
  dataSources, data => _get(data, 'drawerSelected.isLoading', false),
);
export const getDrawerSelectedOptions = createSelector(
  dataSources, data => _get(data, 'drawerSelected.options')
);
export const getDrawerSelectedType = createSelector(
  dataSources, data => _get(data, 'drawerSelected.type', null),
);
// VETTRACK_ANALYSIS_SETTINGS_DATASOURCES_SETTINGS_END

// VETTRACK_ANALYSIS_SETTINGS_RISK_CALCULATIONS_SETTINGS

const settingsRC = `vetTrack.analysis.settings.riskCalculations`;
const rcAcademic = _get(subSections, 'academic.key', 'academic');
const rcFinancial = _get(subSections, 'financial.key', 'financial');
const rcCompliance = _get(subSections, 'compliance.key', 'compliance');
const rcData = state => _get(state, settingsRC, {}); 

export const getRiskCalculationsData = createSelector(
  rcData, data => data,
);

export const getCategoryOptionSelected = createSelector(
  rcData, data => _get(data, 'categoryOptions'),
);
export const getCategoryOptionSelectedLoading = createSelector(
  rcData, data => _get(data, 'categoryOptions.isLoading', false),
);
export const getCategoryOptionSelectedData = createSelector(
  rcData, data => _get(data, 'categoryOptions.options', []),
);

export const makeRCSyncStauses = createSelector(
  rcData, data => _get(data, 'syncData', {}),
);

// ACADEMIC
export const getRcAcademicData = createSelector(
  rcData, data => _get(data, `${rcAcademic}`),
);

export const getRcAcademicDataFetchLoading = createSelector(
  rcData, data => _get(data, `${rcAcademic}.selected.isLoading`, false),
);
export const getRcAcademicSaveLoading = createSelector(
  rcData, data => _get(data, `${rcAcademic}.saved.isLoading`, false),
);
export const getRcAcademicDataResponse = createSelector(
  rcData, data => _get(data, `${rcAcademic}.data`)
);

// FINANCIAL
export const getRcFinancialData = createSelector(
  rcData, data => _get(data, `${rcFinancial}`),
);

export const getRcFinancialDataFetchLoading = createSelector(
  rcData, data => _get(data, `${rcFinancial}.selected.isLoading`, false),
);
export const getRcFinancialSaveLoading = createSelector(
  rcData, data => _get(data, `${rcFinancial}.saved.isLoading`, false),
);
export const getRcFinancialDataResponse = createSelector(
  rcData, data => _get(data, `${rcFinancial}.data`),
);

// COMPLIANCE
export const getRcComplianceData = createSelector(
  rcData, data => _get(data, `${rcCompliance}`),
);

export const getRcComplianceDataFetchLoading = createSelector(
  rcData, data => _get(data, `${rcCompliance}.selected.isLoading`, false),
);
export const getRcComplianceSaveLoading = createSelector(
  rcData, data => _get(data, `${rcCompliance}.saved.isLoading`, false),
);
export const getRcComplianceDataResponse = createSelector(
  rcData, data => _get(data, `${rcCompliance}.data`),
);

export const getRcSettingsByPath = path => (
  createSelector(rcData, data => _get(data, `${path}`))
);
// VETTRACK_ANALYSIS_SETTINGS_RISK_CALCULATIONS_SETTINGS_END

// VETTRACK_ANALYSIS_SETTINGS_RISK_INDICATORS_SETTINGS
const settingsRI = `vetTrack.analysis.settings.riskIndicators`;
const riAcademic = _get(subSections, 'academic.key', 'academic');
const riFinancial = _get(subSections, 'financial.key', 'financial');
const riCompliance = _get(subSections, 'compliance.key', 'compliance');
const riData = state => _get(state, settingsRI, {});

export const getRiskIndicatorsData = createSelector(
  riData, data => data,
);

// ACADEMIC
export const getRiAcademicData = createSelector(
  riData, data => _get(data, `${riAcademic}`),
);

export const getRiAcademicDataFetchLoading = createSelector(
  riData, data => _get(data, `${riAcademic}.selected.isLoading`, false),
);
export const getRiAcademicSaveLoading = createSelector(
  riData, data => _get(data, `${riAcademic}.saved.isLoading`, false),
);
export const getRiAcademicDataResponse = createSelector(
  riData, data => _get(data, `${riAcademic}.data`),
);

export const makeRISyncStauses = createSelector(
  riData, data => _get(data, 'syncData', {}),
);

// FINANCIAL
export const getRiFinancialData = createSelector(
  riData, data => _get(data, `${riFinancial}`),
);

export const getRiFinancialDataFetchLoading = createSelector(
  riData, data => _get(data, `${riFinancial}.selected.isLoading`, false),
);
export const getRiFinancialSaveLoading = createSelector(
  riData, data => _get(data, `${riFinancial}.saved.isLoading`, false),
);
export const getRiFinancialDataResponse = createSelector(
  riData, data => _get(data, `${riFinancial}.data`),
);

// COMPLIANCE
export const getRiComplianceData = createSelector(
  riData, data => _get(data, `${riCompliance}`),
);

export const getRiComplianceDataFetchLoading = createSelector(
  riData, data => _get(data, `${riCompliance}.selected.isLoading`, false),
);
export const getRiComplianceSaveLoading = createSelector(
  riData, data => _get(data, `${riCompliance}.saved.isLoading`, false),
);
export const getRiComplianceDataResponse = createSelector(
  riData, data => _get(data, `${riCompliance}.data`),
);

export const getRiSettingsByPath = path => (
  createSelector(riData, data => _get(data, `${path}`, undefined))
);
// VETTRACK_ANALYSIS_SETTINGS_RISK_INDICATORS_SETTINGS_END
