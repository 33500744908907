import React, {useEffect, useState, Fragment} from 'react';
import {Button, Form, Input} from "antd";
import {SearchOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchConsumers} from "../../../../actions/consumer.action";
import menuData from "../../../layout/dashboardManagement/menuData";

import {getSubUserPartnerId} from "../../../../selectors/onboarding/auth.selector";

const HeaderControls = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const userPartner = useSelector(getSubUserPartnerId);
    const [searchString, setSearchString] = useState(undefined);

    // todo:: debounce the request (lodash is in a different branch at the moment)
    useEffect(() => {
        if (searchString !== undefined) {
            dispatch(fetchConsumers({
                query: {
                    name: searchString
                }
            }, userPartner))
        }
    }, [searchString]);

    return (
        <Fragment>

            <Form form={form} name="horizontal_login" layout="inline" size={"large"}>
                <Form.Item style={{marginTop: 10}}>
                    <Link to={menuData.manageTenantAdmin.url}>
                        <Button style={{height:"100%"}}>Stat Management</Button>
                    </Link>
                </Form.Item>
                <Form.Item
                style={{marginTop: 10}}
                    name="searchConsumer">
                    <Input
                        prefix={<SearchOutlined className="site-form-item-icon"/>}
                        type="text"
                        placeholder="Search" onChange={e => setSearchString(e.target.value)}/>
                </Form.Item>
                <Form.Item
                style={{marginTop: 10}}>
                    <Link
                        to={{
                            pathname: "/setup/consumer/create"
                        }}>
                        <Button
                            type="primary"
                            htmlType="button">
                            Add New
                        </Button>
                    </Link>
                </Form.Item>
            </Form>
        </Fragment>
    );
};

export default HeaderControls;