import React, { Component } from "react";
import { connect } from 'react-redux';
import { Slider } from 'rsuite';
import { updateFilter } from "../../../../actions/filter.action";

class YearFilter extends Component {

  onFilterChange = (year) => {
      const {dispatch} = this.props;
      dispatch(updateFilter({year}))
  }

  onSelectionChange = (event) => {
      const year = event.target.value;;
      if (year) {
          this.onFilterChange(parseInt(year))
      }
  }

  render() {
    const {year, maxYear} = this.props.filters;
    const minYear = maxYear - 10;

    if (this.props.mobile) {
        return (
            <div className="form-group">
                <label htmlFor="sel1">Year</label>
                <select className="form-control" id="sel1" onChange={this.onSelectionChange} value={year}>
                    {
                        [...Array(maxYear-minYear+1).keys()].map(i => {
                            const value = minYear + i
                            return <option value={value} key={i}>{value}</option>
                        })
                    }
                </select>
            </div>
        )
    }

    return (
        <Slider
              defaultValue={year}
              className="year-slider"
              step={1}
              graduated
              min={minYear}
              max={maxYear}
              tooltip={false}
              renderMark={mark => {
                return mark;
              }}
              onChange={this.onFilterChange}
            />
    );
  }
}

const mapStateToProps = (state) => {
    return {
        filters: state.filters.header
    }
}

export default connect(mapStateToProps)(YearFilter);
