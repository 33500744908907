import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import LineChartCard from '../../../../../common/lineChartCard/LineChartCard';
import BarChartCard from './partials/barChartCard/BarChartCard';
import Loader from '../../../../../../../../common/layout/Loader';

const Overview = ({
  onPopupOpen,
  lineChartData,
  barChartData,
  pieChartData,
  dataLoading,
}) => (
  <div>
    <Loader isLoading={dataLoading}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <LineChartCard
            title="Compliance Risk Over Time"
            data={lineChartData}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div
            onClick={() => onPopupOpen('durationToExpire')}
            className="overview-clickable-card"
            onKeyDown={() => {}}
            tabIndex={0}
            role="button"
          >
            <BarChartCard
              name="Duration to Expire"
              data={barChartData}
              enabled={barChartData?.enabled}
            />
          </div>
        </Col>
      </Row>
    </Loader>
  </div>
);

Overview.propTypes = {
  onPopupOpen: PropTypes.func,
  lineChartData: PropTypes.object,
  barChartData: PropTypes.object,
  pieChartData: PropTypes.object,
  dataLoading: PropTypes.bool,
};

export default Overview;
